import { StylisticSet } from "./font";
import { StepConfig } from "./types";

export const clearElements = (animationElement: HTMLDivElement) => {
  animationElement.innerHTML = "";
};

export const setCss = (
  element: HTMLElement,
  style: Partial<CSSStyleDeclaration>
) => {
  for (const [key, value] of Object.entries(style)) {
    element.style[key] = value;
  }
};

export const resetElements = (
  animationElement: HTMLDivElement,
  textElement: HTMLDivElement,
  item: StepConfig,
  state: Record<string, any>
) => {
  state.lines = [];
  textElement.innerHTML = "";
  textElement.removeAttribute("style");

  if (item.style) {
    Object.entries(item.style).forEach(([key, value]) => {
      textElement.style[key] = value;
    });
  }

  item.lines.forEach((line) => {
    const lineElement = document.createElement("span");
    lineElement.innerText = line.text;
    lineElement.style.fontSize = `${line.size}vw`;
    lineElement.style.scale = `${line.scale || 1}`;
    lineElement.style.transform = `translate3d(${line.x || 0}vw, ${
      line.y || 0
    }vw, 0)`;

    textElement.appendChild(lineElement);
    state.lines.push(lineElement);
  });
};

export const interpolateStylisticSet = (
  progress: number,
  start: StylisticSet,
  target: StylisticSet
): StylisticSet => {
  return {
    ital: start.ital + (target.ital - start.ital) * progress,
    wght: start.wght + (target.wght - start.wght) * progress,
    wdth: start.wdth + (target.wdth - start.wdth) * progress,
  };
};

export type SubSection = {
  percent: number;
  handler: (progress: number) => void;
};

export const createSubSections = (sections: SubSection[]) => {
  const totalProgressFactor = sections.reduce(
    (acc, section) => (acc += section.percent),
    0
  );

  return {
    compute: (progress: number) => {
      const remappedProgress = totalProgressFactor * progress;
      let currentSection: SubSection | undefined = undefined;
      let currentSectionProgress = 0;

      for (const section of sections) {
        if (
          remappedProgress >= currentSectionProgress &&
          remappedProgress < currentSectionProgress + section.percent
        ) {
          currentSection = section;
          break;
        }

        currentSectionProgress += section.percent;
      }

      if (currentSection) {
        const sectionProgress =
          (remappedProgress - currentSectionProgress) / currentSection.percent;
        currentSection.handler(sectionProgress);
      }
    },
  };
};

export const isSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent
);
export const isChrome =
  /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

export const mapRange = (
  value: number,
  low1: number,
  high1: number,
  low2: number,
  high2: number
) => {
  return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1);
};

export const pickItem = <T>(array: Array<T>) => {
  return array[Math.floor(Math.random() * array.length)];
};

export const pickNextItem = <T>(item: T, array: Array<T>) => {
  const index = array.indexOf(item);
  const randomIndex = Math.floor(Math.random() * (array.length - 1)) + 1;
  const nextIndex = (index + randomIndex) % array.length;
  return array[nextIndex];
};

export const shuffle = <T>(array: Array<T>) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export const interpolateArrays = (
  progress: number,
  origin: number[],
  target: number[]
) => {
  return origin.map((it, index) => it + (target[index] - it) * progress);
};

export const clamp = (value: number, min: number, max: number) =>
  Math.min(Math.max(value, min), max);

export type WordChunk = {
  text: string;
  style?: Partial<CSSStyleDeclaration>;
};

export const writer = (
  element: HTMLElement,
  initialChunks: WordChunk[] = []
) => {
  let lastChunks: WordChunk[] = initialChunks;
  return {
    render(progress: number, chunks: WordChunk[]) {
      const word = chunks.map((it) => it.text).join("");

      if (word === element.innerText.split("\n").join("")) {
        lastChunks = chunks;
      }

      const diff = Math.abs(lastChunks.length - chunks.length);

      const longerChunks =
        lastChunks.length > chunks.length ? lastChunks : chunks;

      const finalCunks = longerChunks.slice(
        0,
        longerChunks.length -
          Math.floor(
            (lastChunks.length > chunks.length ? progress : 1 - progress) *
              (diff + 1)
          )
      );

      element.innerHTML = "";

      finalCunks.forEach((it) => {
        const child = document.createElement("span");
        child.innerText = it.text;
        setCss(child, it.style || {});
        element.appendChild(child);
      });
    },
  };
};
