import { writer } from "../utils";
import { setBackground, setColor, setFade } from "../state";
import { defaultFontSize } from "./stepsConstants";
import { CreateStep } from "../types";
import {
  clearElements,
  createSubSections,
  isChrome,
  isSafari,
  setCss,
} from "../utils";

const duration = 8000;
const maxWdth = isChrome || isSafari ? 96 : 100;
let finish = false;

export const createStep15: CreateStep = (animationElement, textElement) => {
  const line = document.createElement("span");

  let wr = writer(line);

  const subSections = createSubSections([
    {
      percent: 0.05,
      handler: (progress) => {
        wr.render(progress, [
          { text: "A" },
          { text: "a" },
          { text: "l" },
          {
            text: "G",
            style: {
              fontFeatureSettings: "'sso1'",
            },
          },
          { text: "g" },
          { text: "o" },
          { text: "R" },
          { text: "r" },
          { text: "i" },
          { text: "t" },
          { text: "h" },
          { text: "m" },
        ]);
      },
    },
    {
      percent: 0.1,
      handler: () => {},
    },
    {
      percent: 0.03,
      handler: (progress) => {
        wr.render(progress, [
          { text: "A" },
          { text: "a" },
          { text: "l" },
          {
            text: "G",
            style: {
              fontFeatureSettings: "'sso1'",
            },
          },
          { text: "g" },
          { text: "o" },
          { text: "R" },
        ]);
      },
    },
    {
      percent: 0.05,
      handler: (progress) => {
        wr.render(progress, [
          { text: "A" },
          { text: "a" },
          { text: "l" },
          {
            text: "G",
            style: {
              fontFeatureSettings: "'sso1'",
            },
          },
          { text: "g" },
          { text: "o" },
          { text: "R" },
          {
            text: "ri",
            style: {
              color: "rgb(0, 255, 0)",
              fontFeatureSettings: '"dlig"',
            },
          },
          { text: "t" },
          { text: "h" },
          { text: "m" },
        ]);
      },
    },
    {
      percent: 0.06,
      handler: () => {},
    },
    {
      percent: 0.05,
      handler: (progress) => {
        wr.render(progress, [{ text: "A" }, { text: "a" }, { text: "l" }]);
      },
    },
    {
      percent: 0.05,
      handler: (progress) => {
        wr.render(progress, [
          { text: "A" },
          { text: "a" },
          { text: "l" },
          {
            text: "G",
            style: {
              color: "rgb(0, 255, 0)",
              fontFeatureSettings: '"ss10"',
            },
          },
          { text: "g" },
          { text: "o" },
          { text: "R" },
          {
            text: "ri",
            style: {
              fontFeatureSettings: '"dlig"',
            },
          },
          { text: "t" },
          { text: "h" },
          { text: "m" },
        ]);
      },
    },
    {
      percent: 0.1,
      handler: () => {},
    },
    {
      percent: 0.05,
      handler: (progress) => {
        wr.render(progress, [{ text: "A" }]);
      },
    },
    {
      percent: 0.05,
      handler: (progress) => {
        wr.render(progress, [
          { text: "A" },
          {
            text: "a",
            style: {
              color: "rgb(0, 255, 0)",
              fontFeatureSettings: '"ss01"',
            },
          },
          { text: "l" },
          {
            text: "G",
            style: {
              color: "rgb(0, 255, 0)",
              fontFeatureSettings: '"ss01"',
            },
          },
          {
            text: "g",
            style: {
              color: "rgb(0, 255, 0)",
              fontFeatureSettings: '"ss01"',
            },
          },
          { text: "o" },
          {
            text: "R",
            style: {
              color: "rgb(0, 255, 0)",
              fontFeatureSettings: '"ss01"',
            },
          },
          {
            text: "r",
            style: {
              color: "rgb(0, 255, 0)",
              fontFeatureSettings: '"ss01"',
            },
          },
          { text: "i" },
          { text: "t" },
          {
            text: "h",
            style: {
              color: "rgb(0, 255, 0)",
              fontFeatureSettings: '"ss01"',
            },
          },
          {
            text: "m",
            style: {
              color: "rgb(0, 255, 0)",
              fontFeatureSettings: '"ss01"',
            },
          },
        ]);
      },
    },
    {
      percent: 0.1,
      handler: () => {
        // if (!finish) {
        //   setFade(true);
        //   setColor(0, 0, 0);
        //   finish = true;
        // }
      },
    },
  ]);

  return {
    duration,
    onStart() {
      console.log("step 15");
      clearElements(textElement);
      setBackground(0, 0, 0);
      setColor(255, 255, 255);
      setFade(false);

      setCss(line, {
        textAlign: "start",
        whiteSpace: "nowrap",
      });

      const emptyLine = document.createElement("span");
      textElement.appendChild(emptyLine.cloneNode());
      textElement.appendChild(emptyLine.cloneNode());
      textElement.appendChild(emptyLine.cloneNode());
      textElement.appendChild(line);

      setCss(textElement, {
        fontVariationSettings: `"wdth" ${maxWdth}, "wght" ${500}`,
        whiteSpace: "nowrap",
        fontSize: defaultFontSize,
        justifyContent: "flex-end",
        fontFeatureSettings: null,
      });
    },

    onEnd() {
      setFade(true);
      setCss(textElement, {
        fontVariationSettings: `"wdth" ${96}, "wght" ${500}`,
        whiteSpace: "nowrap",
        fontSize: defaultFontSize,
        justifyContent: "flex-end",
        fontFeatureSettings: null,
      });
    },
    handler: (progress) => {
      subSections.compute(progress);
    },
  };
};
