import { easeInOutExpo } from "js-easing-functions";
import { clamp } from "../utils";
import { setBackground } from "../state";
import { CreateStep } from "../types";
import { isChrome, isSafari, setCss } from "../utils";

const duration = 1000;
const animationDuration = 1000;

export const createStep2: CreateStep = (animationElement, textElement) => {
  return {
    duration,
    onStart() {
      console.log("step 2");
      setBackground(0, 0, 0);
    },
    handler: (progress) => {
      const animationProgress = easeInOutExpo(
        clamp((progress * duration) / animationDuration, 0, 1),
        0,
        1,
        1
      );

      setCss(textElement, {
        fontVariationSettings: `"wdth" ${
          (isChrome || isSafari ? 96 : 100) -
          animationProgress * (isChrome || isSafari ? 46 : 50)
        }, "wght" ${500 - animationProgress * 400}`,
      });
    },
  };
};
