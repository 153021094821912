import { easeInOutExpo } from "js-easing-functions";
import { clamp } from "../utils";
import { setBackground } from "../state";
import { CreateStep } from "../types";
import { isChrome, isSafari, setCss } from "../utils";

const duration = 2000;
const animationDuration = 1200;

export const createStep6: CreateStep = (animationElement, textElement) => {
  return {
    duration,
    onStart() {
      console.log("step 6");
      setBackground(0, 0, 0);
    },
    onEnd() {
      setCss(textElement, {
        fontFeatureSettings: null,
      });
    },
    handler: (progress) => {
      const animationProgress = (progress * duration) / animationDuration;

      let lineIndex = 0;
      for (const line of Array.from(textElement.children)) {
        const wdth =
          50 +
          (isChrome || isSafari ? 96 : 100) *
            easeInOutExpo(
              clamp(animationProgress - lineIndex * 0.08, 0, 1),
              0,
              1,
              1
            );

        const wght =
          100 +
          800 *
            easeInOutExpo(
              clamp(animationProgress - lineIndex * 0.08, 0, 1),
              0,
              1,
              1
            );

        const ital =
          100 *
          easeInOutExpo(
            clamp(animationProgress - lineIndex * 0.08, 0, 1),
            0,
            1,
            1
          );

        setCss(line as HTMLElement, {
          fontVariationSettings: `"wdth" ${wdth}, "wght" ${wght}, "ital" ${ital}`,
          padding: "1em",
          margin: "-1em",
        });
        lineIndex++;
      }
    },
  };
};
