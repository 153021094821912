import { easeInOutExpo, easeOutExpo } from "js-easing-functions";
import { clamp, createSubSections } from "../utils";
import { setBackground, setColor, setFade } from "../state";
import { row1Class, rows3Class } from "./stepsConstants";
import { CreateStep } from "../types";
import { clearElements, setCss } from "../utils";

const duration = 4500;
const animationDuration = 4500;

export const createStep8: CreateStep = (animationElement, textElement) => {
  const container = document.createElement("div");
  const x1 = document.createElement("span");
  x1.innerText = "x";
  const x2 = document.createElement("span");
  x2.innerText = "x";

  const subSections = createSubSections([
    {
      percent: 0.2,
      handler(progress) {
        const p = easeOutExpo(progress, 0, 1, 1);
        setCss(textElement, {
          transform: `translate(${(1 - p) * 100}%, 0)`,
        });
      },
    },
    {
      percent: 0.8,
      handler(progress) {
        const animationProgress = clamp(
          (progress * duration) / animationDuration,
          0,
          1
        );

        const mult = Math.abs(progress - 0.5) * 2;
        const p = easeInOutExpo(mult, 0, 1, 1);
        const wdth = 70 + p * 80;
        const wght = 100 + p * 800;
        const ital = 100 * p;

        const iWdth = 150 - p * 80;
        const iWght = 900 - p * 800;
        const iItal = 100 * (1 - p);

        setCss(x1, {
          fontVariationSettings: `"wdth" ${wdth}, "wght" ${wght}, "ital" ${iItal}`,
        });

        setCss(x2, {
          fontVariationSettings: `"wdth" ${iWdth}, "wght" ${iWght}, "ital" ${iItal}`,
        });
      },
    },
  ]);

  return {
    duration,
    onStart() {
      console.log("step 8");
      setFade(false);
      setBackground(235, 233, 230);
      setColor(0, 0, 0);
      clearElements(textElement);

      container.appendChild(x1);
      container.appendChild(x2);
      textElement.appendChild(container);
      textElement.classList.remove(row1Class);
      textElement.classList.remove(rows3Class);

      setCss(x2, {
        fontVariationSettings: `"wdth" ${70}, "wght" ${100}`,
      });

      setCss(x1, {
        fontVariationSettings: `"wdth" ${150}, "wght" ${900}`,
        position: "relative",
        zIndex: "1000000",
      });

      textElement.classList.add("xx");

      setCss(textElement, {
        transform: "translate(100%, 0)",
      });
    },

    onEnd: () => {
      textElement.classList.remove("xx");
      setFade(true);
      setCss(textElement, {
        transform: null,
      });
    },
    handler: (progress) => {
      subSections.compute(progress);
    },
  };
};
