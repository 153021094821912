import { easeInOutExpo } from "js-easing-functions";
import { writer } from "../utils";
import { setBackground, setColor, setFade } from "../state";
import { defaultFontSize } from "./stepsConstants";
import { CreateStep } from "../types";
import {
  clearElements,
  createSubSections,
  isChrome,
  isSafari,
  setCss,
} from "../utils";

const duration = 15000;
const maxWdth = isChrome || isSafari ? 96 : 100;
let finish = false;

export const createStep18: CreateStep = (animationElement, textElement) => {
  const line = document.createElement("span");

  let wr = writer(line);

  const subSections = createSubSections([
    {
      percent: 0.05,
      handler: (progress) => {
        setBackground(0, 255, 0);
        wr.render(progress, [
          { text: "M" },
          { text: "m" },
          { text: "a" },
          {
            text: "G",
            style: {
              fontFeatureSettings: "'sso1'",
            },
          },
          { text: "g" },
          { text: "N" },
          { text: "n" },
          { text: "e" },
          { text: "t" },
          { text: "i" },
          { text: "c" },
        ]);
      },
    },
    {
      percent: 0.04,
      handler: () => {},
    },
    {
      percent: 0.05,
      handler: (progress) => {
        wr.render(progress, [
          {
            text: "M",
            style: {
              fontFeatureSettings: '"ss01"',
            },
          },
        ]);
      },
    },
    {
      percent: 0.05,
      handler: (progress) => {
        setColor(0, 0, 0);
        wr.render(progress, [
          {
            text: "M",
            style: {
              fontFeatureSettings: '"ss01"',
            },
          },
          {
            text: "m",
            style: {
              fontFeatureSettings: '"ss01"',
            },
          },
          {
            text: "a",
            style: {
              fontFeatureSettings: '"ss01"',
            },
          },
          {
            text: "G",
            style: {
              fontFeatureSettings: '"ss01"',
            },
          },
          {
            text: "g",
            style: {
              fontFeatureSettings: '"ss01"',
            },
          },
          {
            text: "N",
            style: {
              fontFeatureSettings: '"ss01"',
            },
          },
          {
            text: "n",
            style: {
              fontFeatureSettings: '"ss01"',
            },
          },
          {
            text: "e",
            style: {
              color: "rgb(255, 255, 255)",
              fontFeatureSettings: '"ss01"',
            },
          },
          {
            text: "t",
            style: {
              color: "rgb(255, 255, 255)",
              fontFeatureSettings: '"ss01"',
            },
          },
          {
            text: "i",
            style: {
              color: "rgb(255, 255, 255)",
              fontFeatureSettings: '"ss01"',
            },
          },
          {
            text: "c",
            style: {
              color: "rgb(255, 255, 255)",
              fontFeatureSettings: '"ss01"',
            },
          },
        ]);
      },
    },
    {
      percent: 0.04,
      handler: () => {},
    },
    {
      percent: 0.1,
      handler: (progress) => {
        const p = easeInOutExpo(progress, 0, 1, 1);
        setCss(line, {
          fontVariationSettings: `"wdth" ${
            maxWdth - (maxWdth - 50) * p
          }, "wght" ${500}`,
        });
      },
    },
    {
      percent: 0.02,
      handler: () => {},
    },
    {
      percent: 0.05,
      handler: (progress) => {
        setCss(line, {
          fontVariationSettings: `"wdth" ${50}, "wght" ${500}`,
        });
        wr.render(progress, []);
      },
    },
    {
      percent: 0.05,
      handler: (progress) => {
        wr.render(progress, [
          {
            text: "A",
            style: {
              fontFeatureSettings: '"ss01"',
            },
          },
          {
            text: "r",
            style: {
              fontFeatureSettings: '"ss01"',
            },
          },
          {
            text: "t",
            style: {
              fontFeatureSettings: '"ss01"',
            },
          },
          {
            text: "e",
            style: {
              fontFeatureSettings: '"ss01"',
            },
          },
          {
            text: "x",
            style: {
              fontFeatureSettings: '"ss01"',
            },
          },
        ]);
      },
    },
    {
      percent: 0.02,
      handler: () => {},
    },
    {
      percent: 0.04,
      handler: (progress) => {
        wr.render(progress, [
          {
            text: "A",
          },
        ]);
      },
    },
    {
      percent: 0.05,
      handler: (progress) => {
        setCss(textElement, {
          fontFeatureSettings: '"dlig"',
        });

        wr.render(progress, [
          {
            text: "A",
          },
          {
            text: "rt",
            style: {
              color: "rgb(255, 255, 255)",
            },
          },
          {
            text: "e",
          },
          {
            text: "x",
          },
        ]);
      },
    },
    {
      percent: 0.1,
      handler: (progress) => {
        const p = easeInOutExpo(progress, 0, 1, 1);
        setCss(line, {
          fontVariationSettings: `"wdth" ${
            50 + (isChrome || isSafari ? 46 : 50) * p
          }, "wght" ${500}`,
        });
      },
    },
    {
      percent: 0.02,
      handler: () => {
        setCss(line, {
          fontVariationSettings: `"wdth" ${maxWdth}, "wght" ${500}`,
        });
      },
    },
    {
      percent: 0.1,
      handler: () => {
        if (!finish) {
          setFade(true);
          setColor(255, 255, 255);
          setBackground(0, 0, 0);
          finish = true;
        }
      },
    },
  ]);

  return {
    duration,
    onStart() {
      console.log("step 18");
      finish = false;
      clearElements(textElement);
      setBackground(0, 0, 0);
      setColor(255, 255, 255);
      setFade(false);

      setCss(line, {
        textAlign: "start",
        whiteSpace: "nowrap",
      });

      const emptyLine = document.createElement("span");
      textElement.appendChild(emptyLine.cloneNode());
      textElement.appendChild(emptyLine.cloneNode());
      textElement.appendChild(emptyLine.cloneNode());
      textElement.appendChild(line);

      setCss(textElement, {
        fontVariationSettings: `"wdth" ${maxWdth}, "wght" ${500}`,
        whiteSpace: "nowrap",
        fontSize: defaultFontSize,
        justifyContent: "flex-end",
        fontFeatureSettings: null,
      });
    },

    onEnd() {
      setFade(true);
      setCss(textElement, {
        fontVariationSettings: `"wdth" ${96}, "wght" ${500}`,
        whiteSpace: "nowrap",
        fontSize: defaultFontSize,
        justifyContent: "flex-end",
        fontFeatureSettings: null,
      });
    },
    handler: (progress) => {
      subSections.compute(progress);
    },
  };
};
