import { clamp } from "../utils";
import { setBackground } from "../state";
import { artexWord, numLines } from "./stepsConstants";
import { CreateStep } from "../types";
import { clearElements } from "../utils";

const duration = 400;
const animationDuration = 400;

export const createStep3: CreateStep = (animationElement, textElement) => {
  return {
    duration,
    onStart() {
      console.log("step 3");
      setBackground(0, 255, 0);
    },
    handler: (progress) => {
      const animationProgress = clamp(
        (progress * duration) / animationDuration,
        0,
        1
      );

      const num = artexWord.length;
      const currentIndex = num - Math.floor(num * animationProgress);

      clearElements(textElement);
      for (let i = 0; i < numLines; i++) {
        const line = document.createElement("span");
        line.innerText = artexWord.slice(0, currentIndex);
        textElement.appendChild(line);
      }
    },
  };
};
