export const state = {
  background: [0, 0, 0],
  color: [255, 255, 255],
  enableFade: true,
  fadeDuration: 1000,
};

export const setFade = (enable: boolean) => {
  state.enableFade = enable;
};

export const setBackground = (r: number, g: number, b: number) => {
  state.background = [r, g, b];
};

export const setColor = (r: number, g: number, b: number) => {
  state.color = [r, g, b];
};

export const setFadeDuration = (duration: number) => {
  state.fadeDuration = duration;
};
