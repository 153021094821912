import { clamp } from "../utils";
import { setBackground, setColor } from "../state";
import {
  artexWord,
  defaultFontSize,
  numLines,
  row1Class,
  rows3Class,
} from "./stepsConstants";
import { CreateStep } from "../types";
import { clearElements, isChrome, isSafari, setCss } from "../utils";

const duration = 1800;
const animationDuration = 1300;

export const createStep1: CreateStep = (animationElement, textElement) => {
  return {
    duration,
    onStart() {
      console.log("step 1");
      setColor(255, 255, 255);
      setBackground(0, 0, 0);
      clearElements(textElement);

      for (let i = 0; i < numLines; i++) {
        const line = document.createElement("span");
        setCss(line, {
          opacity: "0",
        });
        line.innerHTML =
          "<span>A</span><span>rt</span><span>e</span><span>x</span>";
        textElement.appendChild(line);
        if (i === numLines - 1) {
          setCss(line, {
            opacity: "1",
          });
        }
      }

      textElement.classList.remove(row1Class);
      textElement.classList.add(rows3Class);

      setCss(textElement, {
        fontVariationSettings: `"wdth" ${
          isChrome || isSafari ? 96 : 100
        }, "wght" ${500}`,
        fontFeatureSettings: '"dlig"',
        fontSize: defaultFontSize,
      });
    },
    handler: (progress) => {
      const animationProgress = clamp(
        (progress * duration) / animationDuration,
        0,
        1
      );

      const currentIndex =
        numLines - Math.floor((numLines + 1) * animationProgress);

      const line = textElement.children[currentIndex] as HTMLElement;
      if (line) setCss(line, { opacity: "1" });
    },
  };
};
