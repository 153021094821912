"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Step = void 0;
var Step = /** @class */ (function () {
    function Step() {
        this.duration = 0;
    }
    return Step;
}());
exports.Step = Step;
