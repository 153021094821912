import { createStep1 } from "./step-1";
import { createStep13 } from "./step-13_44";
import { createStep14 } from "./step-14_mode-push";
import { createLineSteps } from "./lineSteps";
import { createStep2 } from "./step-2";
import { createStep3 } from "./step-3";
import { createStep4 } from "./step-4";
import { createStep6 } from "./step-6";
import { createStep7 } from "./step-7";
import { createStep8 } from "./step-8_X";
import { createStep9 } from "./step-9_le-mans";
import { createStep15 } from "./step-15_algorithm";
import { createStepDezoom } from "./step-16_dezoom";
import { createStep17 } from "./step-17";
import { createDelay } from "./delay";
import { createStep18 } from "./step-18_magnetic";
const rUrl = require("../../r.svg");
const mUrl = require("../../m.svg");

export const stepCreators = [
  createStep1,
  createStep2,
  createStep3,
  createStep4,
  createStep6,
  createStep7,
  createStep8,
  // createStep9,
  // createStep13,
  // createStepDezoom(rUrl),
  createStep15,
  createStep14,
  // createLineSteps(
  //   ["Carbon", "Architecture", "Nanotech"],
  //   [0, 0, 0],
  //   [255, 255, 255]
  // ),
  // createStep17,
  // createDelay(500),
  // createStepDezoom(mUrl),
  // createDelay(500),
  createStep18,
];
