import { clamp } from "../utils";
import { movementWord, numLines } from "./stepsConstants";
import { CreateStep } from "../types";
import { clearElements } from "../utils";

const duration = 1200;
const animationDuration = 600;

export const createStep4: CreateStep = (animationElement, textElement) => {
  return {
    duration,
    onStart: () => {
      console.log("step 4");
    },
    handler: (progress) => {
      const animationProgress = clamp(
        (progress * duration) / animationDuration,
        0,
        1
      );

      const num = movementWord.length;
      const currentIndex = Math.floor(num * animationProgress);

      clearElements(textElement);
      for (let i = 0; i < numLines; i++) {
        const line = document.createElement("span");
        line.innerText = movementWord.slice(0, currentIndex);
        textElement.appendChild(line);
      }
    },
  };
};
