import { easeInOutCubic, easeInOutQuad } from "js-easing-functions";
import { Animator, Timeline } from "optimo-animator";
import { stepCreators } from "./steps";
import { state } from "./state";
import { clamp, interpolateArrays } from "./utils";

enum Actions {
  Start = "start",
  Stop = "stop",
  Reset = "reset",
}

const colors = [
  {
    background: [255, 0, 0],
    text: [255, 255, 255],
  },
  {
    background: [234, 231, 220],
    text: [0, 0, 0],
  },
  {
    background: [0, 0, 0],
    text: [255, 255, 255],
  },
  {
    background: [16, 121, 1],
    text: [255, 255, 255],
  },
];

const main = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const delay = urlParams.get("delay") === "true";

  const animationElement = document.querySelector(
    ".animation"
  ) as HTMLDivElement;

  const textElement = document.querySelector(
    ".text-container"
  ) as HTMLDivElement;

  const steps = stepCreators.map((it) => it(animationElement, textElement));

  const timeline = new Timeline(steps);
  const animator = new Animator([timeline]);

  let firstStep = true;
  const transitionDuration = 1200;

  let colorTransitionDuration = 1000;
  let backgroundChangeTime = 0;
  let currentBackground = state.background;
  let lastBackground = currentBackground;
  let colorChangeTime = 0;
  let currentColor = state.color;
  let lastColor = currentColor;
  let stepTime = 0;

  animator.onStep((elapsedTime) => {
    firstStep = false;
    stepTime = elapsedTime;
  });

  animator.onTick((elapsedTime) => {
    if (state.background !== currentBackground) {
      backgroundChangeTime = elapsedTime;
      lastBackground = currentBackground;
      currentBackground = state.background;
    }

    if (state.color !== currentColor) {
      colorChangeTime = elapsedTime;
      lastColor = currentColor;
      currentColor = state.color;
    }

    const bgProgress = easeInOutQuad(
      clamp((elapsedTime - backgroundChangeTime) / state.fadeDuration, 0, 1),
      0,
      1,
      1
    );

    const cProgress = easeInOutQuad(
      clamp((elapsedTime - colorChangeTime) / state.fadeDuration, 0, 1),
      0,
      1,
      1
    );

    // const [bR, bG, bA] = interpolateArrays(
    //   bgProgress,
    //   lastBackground,
    //   currentBackground
    // );

    // const [tR, tG, tA] = interpolateArrays(cProgress, lastColor, currentColor);

    if (state.enableFade) {
      const [bR, bG, bA] = interpolateArrays(
        bgProgress,
        lastBackground,
        currentBackground
      );

      const [tR, tG, tA] = interpolateArrays(
        cProgress,
        lastColor,
        currentColor
      );
      animationElement.style.backgroundColor = `rgb(${bR}, ${bG}, ${bA})`;
      animationElement.style.color = `rgb(${tR}, ${tG}, ${tA})`;
    } else {
      const [bR, bG, bA] = currentBackground;
      const [tR, tG, tA] = currentColor;

      animationElement.style.backgroundColor = `rgb(${bR}, ${bG}, ${bA})`;
      animationElement.style.color = `rgb(${tR}, ${tG}, ${tA})`;
    }
  });

  if (delay) {
    animator.stop();
    animator.startTime = 0;
    animator.computeFrameAt(0);
    window.addEventListener(
      "message",
      ({ data }) => {
        const { action } = data;
        if (action === Actions.Start) {
          animator.start();
        } else if (action === Actions.Stop) {
          animator.stop();
        } else if (action === Actions.Reset) {
          animator.startTime = 0;
          animator.computeFrameAt(0);
        }
      },
      false
    );
  } else {
    animator.start();
  }

  (window as any).animator = animator;
};

main();
