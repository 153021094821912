"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Timeline = void 0;
var Timeline = /** @class */ (function () {
    function Timeline(steps) {
        this._steps = steps;
        this._lastStepTime = 0;
        this._lastStep = null;
    }
    Object.defineProperty(Timeline.prototype, "totalDuration", {
        get: function () {
            return this._steps.reduce(function (acc, step) { return (acc += step.duration); }, 0);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Timeline.prototype, "lastStep", {
        get: function () {
            return this._lastStep;
        },
        set: function (lastStep) {
            this._lastStep = lastStep;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Timeline.prototype, "lastStepTime", {
        get: function () {
            return this._lastStepTime;
        },
        set: function (lastStepTime) {
            this._lastStepTime = lastStepTime;
        },
        enumerable: false,
        configurable: true
    });
    Timeline.prototype.getCurrentStep = function (time) {
        var currentTime = 0;
        for (var _i = 0, _a = this._steps; _i < _a.length; _i++) {
            var step = _a[_i];
            if (time <= currentTime + step.duration)
                return step;
            currentTime += step.duration;
        }
        return this._steps[this._steps.length - 1];
    };
    Timeline.prototype.getDurationUntilStep = function (step) {
        var stepIndex = this._steps.indexOf(step);
        var beforeSteps = this._steps.slice(0, stepIndex);
        return beforeSteps.reduce(function (acc, it) { return (acc += it.duration); }, 0);
    };
    Timeline.prototype.getDuration = function () {
        return this._steps.reduce(function (duration, step) {
            duration += step.duration;
            return duration;
        }, 0);
    };
    return Timeline;
}());
exports.Timeline = Timeline;
