import { easeInOutExpo } from "js-easing-functions";
import { setBackground, setColor, setFade } from "../state";
import { row1Class, rows3Class } from "./stepsConstants";
import { CreateStep } from "../types";
import { clearElements, setCss } from "../utils";

const duration = 3000;

export const createStep14: CreateStep = (animationElement, textElement) => {
  const line = document.createElement("span");
  line.innerHTML = "MODE<br>PUSH";

  return {
    duration,
    onStart() {
      console.log("step 14");
      setFade(true);
      clearElements(textElement);
      setBackground(0, 0, 0);
      setColor(255, 0, 0);

      textElement.appendChild(line);
      textElement.classList.remove(row1Class);
      textElement.classList.remove(rows3Class);

      setCss(line, {
        lineHeight: "0.8",
      });

      setCss(textElement, {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        fontVariationSettings: `"wdth" ${150}, "wght" ${500}`,
        fontSize: "20vw",
        whiteSpace: "nowrap",
        marginTop: "0.1em",
        marginLeft: "-0.2em",
      });

      setCss(line, {
        padding: "0.4em",
      });
    },
    onEnd: () => {
      setCss(textElement, {
        display: null,
        justifyContent: null,
        alignItems: null,
        flexDirection: null,
        fontVariationSettings: null,
        fontSize: null,
        whiteSpace: null,
        marginTop: null,
        marginLeft: null,
      });
    },
    handler: (progress) => {
      const p = easeInOutExpo(progress, 0, 1, 1);
      const ital = p * 100;
      const wght = p * 400 + 500;
      setCss(line, {
        fontVariationSettings: `"wdth" ${150}, "wght" ${wght}, "ital" ${ital}`,
      });
    },
  };
};
