import { easeInOutExpo } from "js-easing-functions";
import { clamp, createSubSections, isChrome, isSafari, writer } from "../utils";
import { setBackground, setColor, setFade } from "../state";
import { artexWord, numLines, rows3Class } from "./stepsConstants";
import { CreateStep } from "../types";
import { clearElements, setCss } from "../utils";

const duration = 3000;
const animationDuration = 1500;

export const createStep7: CreateStep = (animationElement, textElement) => {
  let lastLine: HTMLElement = undefined;
  const line = document.createElement("span");
  let wr = writer(line, [
    { text: "A" },
    {
      text: "rt",
      style: {
        fontFeatureSettings: '"dlig"',
      },
    },
    { text: "e" },
    { text: "x" },
  ]);

  const subSections = createSubSections([
    {
      percent: 0.5,
      handler() {
        wr.render(1, [
          { text: "A" },
          {
            text: "rt",
            style: {
              fontFeatureSettings: '"dlig"',
            },
          },
          { text: "e" },
          { text: "x" },
        ]);
      },
    },
    {
      percent: 1,
      handler(progress) {
        wr.render(1, [
          { text: "A" },
          {
            text: "rt",
            style: {
              fontFeatureSettings: '"dlig"',
            },
          },
          { text: "e" },
          { text: "x" },
        ]);
        const animationProgress = easeInOutExpo(progress, 0, 1, 1);

        const wght = 100 + animationProgress * 800;
        const wdth =
          (isChrome || isSafari ? 101 : 100) + animationProgress * 50;
        setCss(textElement, {
          fontVariationSettings: `"wdth" ${wdth}, "wght" ${wght}`,
        });
      },
    },
    {
      percent: 0.2,
      handler: () => {},
    },
    {
      percent: 0.3,
      handler: (progress) => {
        wr.render(progress, []);
      },
    },
    {
      percent: 0.2,
      handler() {},
    },
  ]);

  return {
    duration,
    onStart() {
      console.log("step 7");
      setFade(false);
      setBackground(235, 233, 230);
      setColor(0, 0, 0);
      clearElements(textElement);
      // setFadeDuration(1500);

      for (let i = 0; i < numLines; i++) {
        const line = document.createElement("span");
        setCss(line, {
          opacity: "0",
        });
        line.innerText = artexWord;
        textElement.appendChild(line);
      }

      textElement.appendChild(line);

      lastLine = textElement.children[
        textElement.children.length - 1
      ] as HTMLElement;

      setCss(lastLine, {
        opacity: "1",
      });

      setCss(textElement, {
        fontVariationSettings: `"wdth" ${
          isChrome || isSafari ? 101 : 100
        }, "wght" ${100}`,
        fontFeatureSettings: '"dlig"',
      });

      textElement.classList.add(rows3Class);
    },
    onEnd() {
      setFade(false);
      textElement.classList.remove(rows3Class);
    },
    handler: (progress) => {
      subSections.compute(progress);
    },
  };
};
